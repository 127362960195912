import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Sites from '../components/Sites'
import BlockQuote from '../components/BlockQuote'
import GridImage from '../components/GridImage'
import Faq from '../components/Faq'

function tentangKami() {
  return (
    <Layout>
      <Seo
        title={`Tentang Kami Situs Tips Judi Online Gubelinlab`}
        description={`Tentang Kami Situs Tips Judi Online Gubelinlab Situs Tips Judi Online Indonesia Kemenangan Bermain Judi Online Anda Prioritas Utama Untuk Kami`}
        url={`https://gubelinlab.com/tentang-kami/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-8xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h1 className="text-base text-rose-600 font-semibold tracking-wide uppercase">
              Tentang Kami
            </h1>
            <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Situs Tips Judi Online Indonesia
            </h2>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="250"
                height="350"
                fill="none"
                viewBox="0 0 200 350"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="grid-dotted"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle
                      cx="2"
                      cy="2"
                      r="2"
                      className="text-gray-200"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </pattern>
                </defs>
                <rect width="250" height="350" fill="url(#grid-dotted)" />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none space-y-8">
                <GridImage />
                <BlockQuote
                  htmlText={`Bagi Bettor Pemula Kemenangan Bermain Judi Online Sering Kali Menjadi
                          Sesuatu Yang Di Anggap Susah Maupun Mustahil. Gubelinlab Hadir Dengan
                          Menawarkan Kemudahan Dalam Memenangkan <strong>Judi Online</strong>.
                          Kemenangan Anda Merupakan Prioritas Utama Untuk Kami, Untuk Itu Jika Ada
                          Tips Yang Ingin Anda Dapatkan Silahkan Hubungi Kami.`}
                />
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className="text-lg text-gray-500">
                  Tentang Kami Situs Tips Judi Online Gubelinlab Situs Tips Judi Online Indonesia
                  Kemenangan Bermain Judi Online Anda Prioritas Utama Untuk Kami
                </p>
              </div>
              <div className="mt-5 prose prose-rose prose-lg text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                  Sebagai seorang pemain judi yang mungkin termasuk baru ataupun yang telah lama
                  bermain judi online maka pada awalnya Anda pasti tetap harus memilih situs yang
                  tepat sebelum mulai bermain kan ? Hal tersebut memang sudah wajib dilakukan para
                  bettor agar bisa mengakses game ini, apalagi pada jaman sekarang telah bermunculan
                  banyak situs judi di internet yang dapat Anda pilih. Namun, situs-situs yang
                  bermunculan di internet belum tentu bisa sesuai dengan yang Anda inginkan loh.
                  Oleh karena itu disini gubelinlab.com akan berperan sebagai situs yang menawarkan
                  kepada para bettor tempat bermain judi yang cocok.
                </p>
                <p>
                  Tujuan kami membangun situs gubelinlab.com tentu hanya untuk memberikan pengalaman
                  bermain judi online terbaik dan teraman bagi para bettor. Buktinya dapat Anda
                  lihat dari produk-produk tawaran kami seperti salah satunya situs judi online. Ya,
                  disini kami akan membantu para bettor yang mengalami kesulitan dalam menentukan
                  tempat bermain dan pastinya tempat bermain judi online yang direkomendasikan bukan
                  lah situs abal-abal melainkan situs yang sudah terkenal dan juga dipercayakan oleh
                  banyak orang.
                </p>
                <p>
                  Kami juga berani menjamin bahwa ketika Anda bermain di situs rekomendasian kami
                  maka taruhan yang berlangsung pasti akan berjalan lancar dan pastinya terhindar
                  dari hal-hal mencurigakan atau yang dapat merugikan Anda sendiri. Hal seperti itu
                  memang sudah diperhatikan sebelum kami memilih situs ini dan itulah mengapa banyak
                  sekali pemain judi online yang mempercayakan kami sebagai tempat untuk mencari
                  situs judi online.
                </p>
                <p>
                  Selain menyediakan situs judi yang dapat dipilih, kami juga mempersiapkan
                  artikel-artikel seputar game judi online yang tentu dapat membantu para bettor
                  untuk memenangkan taruhan judi online. Artikel yang disediakan juga bisa diakui
                  keasliannya karena kami tidak sembarangan menuliskan atau mengambil karya orang
                  lain. Justru semuanya merupakan pengalaman bermain judi yang kami miliki, hal
                  seperti ini memang sangat diperlukan oleh situs seperti gubelinlab.com.
                </p>
                <p>
                  Bagi Anda yang hendak mencari tahu informasi lebih jauh mengenai judi online, maka
                  Anda juga diberikan akses untuk menanyakannya langsung kepada kami loh. Lalu
                  caranya bagaimana ? Anda hanya perlu memilih menu “hubungi kami” kemudian mengisi
                  formulir tersebut dengan lengkap dan kirim ke kami. Apabila sudah terkirim maka
                  kami akan berusaha secepat mungkin untuk membalas pertanyaan yang diberikan.
                  Semoga dengan adanya perkenalan singkat dari kami maka para bettor pun menjadi
                  lebih mengenal kami dan tidak ragu menggunakan produk yang terseida di situs
                  gubelinlab.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sites />
      <Faq />
    </Layout>
  )
}

export default tentangKami
