import React from 'react'
function Faq() {
  return (
    <div className="bg-white">
      <div className="max-w-8xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
        <div className="mt-6 pt-10">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Mengapa Harus Menggunakan Produk Kami ?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Salah Satu Alasan Mengapa Anda Harus Menggunakan Produk Yang Disediakan Oleh
                Gubelinlab.Com Adalah Karena Seluruh Produk Yang Tersedia Di Situs Ini Merupakan
                Produk Terbaik Yang Telah Kami Pilih Dengan Sebaik Mungkin. Selain Terbaik, Untuk
                Keasliannya Juga Telah Terbukti Dengan Banyaknya Orang Yang Telah Mempercayai Kami.
              </div>
            </div>

            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Apakah Keamanan Data Terjamin Di Situs Yang Direkomendasikan ?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Tentu Saja Untuk Masalah Keamanan Tidak Perlu Diragukan Lagi Karena Seluruh Situs
                Yang Kami Rekomendasikan Merupakan Situs Judi Yang Telah Diakui Oleh Badan Perjudian
                Internasional Sehingga Seluruh Keamanan Baik Dalam Transaksi Ataupun Data Diri Akan
                Dijaga Sebaik Mungkin.
              </div>
            </div>

            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Apa Keunggulan Produk Gubelinlab.Com?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Anda Bisa Memperoleh Situs-Situs Bermain Judi Online Yang Terbaik, Artikel Mengenai
                Permainan Judi Online Dan Juga Akun Vip Khusus Yang Sangat Diinginkan Para Bettor.
                Produk-Produk Yang Kami Sediakan Itu Tentu Merupakan Produk Terbaik Yang Telah Kami
                Sediakan Khusus Kepada Anda Semua Sehingga Bisa Kami Pastikan Para Bettor Yang
                Menggunakannya Akan Memperoleh Keuntungan.
              </div>
            </div>

            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Bagaimana Cara Berkomunikasi Dengan Kami ?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Jika Para Bettor Ingin Menanyakan Hal Mengenai Permainan Judi Online Maka Anda Bisa
                Langsung Mengunjungi Menu “Hubungi Kami” Yang Telah Tersedia Di Situs Kami. Tentu
                Kami Akan Berusaha Secepatnya Untuk Membalas Pesan Anda.
              </div>
            </div>

            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Apakah Kami Bisa Menjamin Kemenangan Anda ?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Untuk Kemenangan Tentu Saja Tidak Bisa Kami Pastikan Karena Sebenarnya Hal Itu
                Tergantung Pada Kemampuan Masing-Masing Bettor Sehingga Kami Hanya Dapat Sebatas
                Membantu Dengan Memberi Tips-Tips Seputar Permainan Judi Online Kepada Para Bettor.
                Kami Yakin Dengan Beberapa Tips Bermain Eksklusif Dari Kami Maka Anda Akan Bisa
                Memenangkan Seluruh Taruhan Yang Dimainkan.
              </div>
            </div>

            <div>
              <div className="text-lg leading-6 font-medium text-gray-900">
                Artikel Judi Apa Saja Yang Kami Sediakan ?
              </div>
              <div className="mt-2 text-base text-gray-500">
                Artikel Yang Kami Sediakan Di Situs Gubelinlab.Com Tentu Beragam Mulai Dari
                Permainan Poker Online, Taruhan Bola, Slot Online Hingga Casino Online. Seluruh
                Permainan Tersebut Tentu Akan Kami Buat Artikelnya Baik Itu Berupa Tips Dan Trik
                Ataupun Cara Bermainnya Untuk Para Pemula. Hal Tersebut Tentu Sangat Penting Bagi
                Para Pemain Judi Online Yang Menggunakan Jasa Kami.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
